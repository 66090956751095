* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f5f5f5 !important;
  /* -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

.Item_Container {
  /* height: 530px; */
  overflow: auto;
}

.fc {
  width: 100%;
}

a:active {
  color: blue;
}

.Comman_Loader {
  position: absolute;
  top: 50vh;
  left: 50vw;
  z-index: 99999999;
}
.user_salary_card {
  display: flex;
  text-align: center;
  justify-content: center;
}

.Item_Container .fc .fc-button {
  border-radius: 50%;
  padding: 0.3em;
}
.Item_Container .fc .fc-toolbar-title {
  font-size: 20px;
  font-weight: 800;
}

.Item_Container .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}

.Item_Container::-webkit-scrollbar {
  width: 8px;
  background-color: none;
}

.Item_Container::-webkit-scrollbar-thumb {
  background-color: #cfcfcf;
  border-radius: 10px;
}

.NavLink {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #455a64;
  text-decoration: none;
}

.isActive {
  width: 100%;
  color: #2962ff;
  border-radius: 8px;
}

.mesh-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999999;
}

.mesh-loader .circle {
  width: 25px;
  height: 25px;
  position: absolute;
  background: #2962ff;
  border-radius: 50%;
  margin: -12.5px;
  -webkit-animation: mesh 3s ease-in-out infinite;
  animation: mesh 3s ease-in-out infinite -1.5s;
}

.mesh-loader > div .circle:last-child {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.mesh-loader > div {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999999999;
}

.mesh-loader > div:last-child {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

#userList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card {
  max-width: 300px;
  padding: 15px;
  margin-right: 15px;
  width: 300px !important;
}

.userCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px !important;
  margin: 10px;
}
.userCard:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.carousel {
  width: 100%;
  direction: rtl;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}

.carousel.active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.cWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item:first-child {
  margin-right: 0px !important;
}
.item:last-child {
  margin-left: 0px !important;
}

.formDatePicker .css-17s5qag-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.dashbordTable .css-1nf7mbx-MuiTableCell-root {
  border-bottom: unset !important;
}
.dashbordTable .css-1yxa850-MuiTableCell-root {
  border-bottom: unset !important;
}
.upadateData .css-1k4zhvo-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px;
}
.upadateData .css-17s5qag-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px;
}
.updateButton .css-c2sx79-MuiButtonBase-root-MuiButton-root {
  border-radius: 8px;
}
.updateButton .css-e4jz9x-MuiButtonBase-root-MuiButton-root {
  border-radius: 8px;
}

.FormDatePicker .css-17s5qag-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.salaryFild
  .css-qdpsv4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border-radius: 8px !important;
}
.lightDarkMode .css-1jmf72z-MuiButtonBase-root-MuiButton-root {
  padding: 10px 30px;
  border: none;
  color: #455a64;
  background-color: #fff;
}
.lightDarkMode .css-1dt6rxc-MuiButtonGroup-root .MuiButtonGroup-grouped:hover {
  border: unset !important;
}
.orgInfo {
  margin-top: 30px;
  border-radius: 8px !important;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.toggleInfo {
  margin-top: 30px;
  border-radius: 8px !important;
}
.catalogueBox {
  border-radius: 8px !important;
}
.CatalogueBoxSelector {
  border: 2px solid #ccc;
  padding: 5px;
  border-radius: 8px;
}

.CatalogueBoxSelector::before {
  content: unset !important;
}
.dropCat {
  position: relative !important;
}
.dropdownLable {
  top: 9px !important;
  background-color: #f6f6f6 !important;
  z-index: 1;
  padding: 0px 10px !important;
  margin-left: 10px;
  color: #000;
}
.isActive:hover {
  background-color: unset !important;
}

.css-6ra4t3-MuiButtonBase-root-MuiListItem-root:hover {
  border-radius: 0px;
}
.dropCat .css-m6rjbw-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: unset !important;
}
.dropCat .css-bzkr3t-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
  color: unset !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-pv30sa-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 8px !important;
}
.css-1m9yp2r {
  padding: 15px;
}
.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.MuiListItem-button.css-6ra4t3-MuiButtonBase-root-MuiListItem-root {
  padding: 10px;
  position: relative;
}
/* .isActive{
  
} */

.isActive:after {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: #cfcfcf; */

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.isActive:before {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 3px;
  height: 100%;
  background-color: #2962ff;

  border-radius: 10px;
}
.css-d1gxau-MuiButtonBase-root-MuiListItem-root:hover {
  border-radius: 5px;
}
.css-fv4unz-MuiList-root {
  padding-left: unset !important;
  padding-right: unset !important;
}
.css-46bg8x-MuiPaper-root-MuiAlert-root,
.css-tp4eyi-MuiPaper-root-MuiAlert-root {
  border-radius: 8px !important;
}
.MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
  width: fit-content;
  margin-left: auto !important;
  margin-right: auto !important;
}
.css-1jmf72z-MuiButtonBase-root-MuiButton-root {
  transition: 0.5s ease !important;
}
.css-1jmf72z-MuiButtonBase-root-MuiButton-root:hover {
  border: unset !important;
  background-color: #2962ff !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  color: #fff !important;
}
.css-dg22xd-MuiList-root {
  border-top: 5px solid #f6f6f6;
}
a.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.MuiListItem-button.css-d1gxau-MuiButtonBase-root-MuiListItem-root.active {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 8px;
}
.css-dzqv4-MuiPaper-root-MuiCard-root {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.css-6udbqu-MuiPaper-root-MuiCard-root {
  width: 70%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
}

.holidays-alert-dialog .MuiDialogActions-spacing {
  display: flex;
  justify-content: center;
}

@-webkit-keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  50.00001% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  50.00001% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.attendance-container {
  position: relative;
}

.attendance-table::-webkit-scrollbar {
  height: 12px;
}

.attendance-table::-webkit-scrollbar-track {
  background: #ffffff;
}

.attendance-table::-webkit-scrollbar-thumb {
  background-color: #d2cbcb;
  border-radius: 25px;
}

.attendance-table::-webkit-scrollbar-thumb:hover {
  background-color: #9c9494;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
}
.modal-data {
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}
.modal-container {
  position: relative;
  background-color: #ffffff;
  width: 30rem;
  height: 25rem;
  padding: 13px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.modal-container p {
  font-size: 21px;
  font-weight: 600;
  z-index: 99999;
}
.modal-container h2 {
  display: flex;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.curve {
  position: absolute;
  height: 250px;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.curve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  transform: translate(-53%, -61%);
  background-color: hsl(28.88deg 76.81% 47.15%);
  z-index: 0;
  top: -60px;
  left: -20px;
}

.curve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 100%;
  background-color: #eacd2b;
  transform: translate(100%, 63%);
  z-index: 0;
  bottom: 25px;
}
.toggle-fullscreen {
  align-self: center;
  display: flex;
  position: relative;
  text-align: center;
}

.box {
  width: 100px !important;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  /* width: auto; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: unset !important;
}
.box:hover {
  box-shadow: unset !important;
}
.image-icon {
  display: flex;
  gap: 10px;
}

.text-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  padding: 6px;
  color: #000;
  border-radius: 5px;
  white-space: wrap;
}
.holiday-box {
  /* width: 100px !important; */
  text-align: center;
  /* padding: 10px; */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: unset !important;
}
.holiday-title {
  font-size: 14px;
  font-weight: 700;
  max-width: 65px;
  margin-bottom: 5px;
  word-wrap: break-word;
  word-break: wrap;
  padding: 6px;
  color: #000;
  border-radius: 5px;
  white-space: wrap;
}
.eventContent {
  background-color: unset !important;
  border: unset !important;
}
